<template>
  <div class="rule">
    <el-tabs v-model="activeName" @tab-click="handleClick" class="el-nav">
      <el-tab-pane label="文章/视频管理 " name="create">
        <div class="video-list">
          <div class="video-list-top vertical-center">
            视频列表
            <div class="v-btn" style="margin-left: 150px">
              <upload-video :config="videoConfig" @uploadStatus="videoStatus"></upload-video>
            </div>

            <!--            <el-button style="background: #FFA509;border-radius: 6px;border: unset;font-size: 14px;font-weight: 400;color: #FFFFFF;margin-left: 140px">添加视频</el-button>-->
          </div>

          <div class="video-list-bottom">
            <div class="video-list-bottom-left" v-if="vlist.meta && vlist.meta.total !== 0">
              <div class="total-left">
                共 <span class="total-left-text">{{ vlist.meta ? vlist.meta.total : 0 }}</span> 个视频
              </div>
              <div class="v-list">
                <div
                  class="list-item"
                  v-for="(item, index) in vlist.data"
                  :key="index"
                  @click="select(item.name, item.id, item.content, item.cover_url)"
                  :class="item.id === form.media_id ? 'list-item-act' : ''"
                >
                  <div class="list-item-top white-space">{{ item.name }}</div>
                  <div class="list-item-bottom">
                    <span class="list-item-bottom-success" v-if="item.content && item.content.m3u8">上传成功</span>
                    <span class="list-item-bottom-success" v-else style="color: #93999f"><i class="el-icon-loading"></i>&nbsp转码中</span>
                    <span class="list-item-bottom-num">{{ (item.size / 1024).toFixed(3) }}MB</span>
                    <div class="bottom-del" style="margin-left: auto">
                      <img src="~assets/images/index/delete.png" v-if="item.id !== delId" alt="" class="delete" />
                      <img src="~assets/images/index/delete-hover.png" alt="" v-if="item.id !== delId" class="delete-hover" @click.stop="deletevideo(item.id)" />
                    </div>
                  </div>
                </div>
              </div>
              <!--  分页   -->
              <el-pagination
                v-if="vlist.meta && vlist.meta.last_page > 1"
                class="page"
                background
                @current-change="handleCurrentChange"
                :current-page="page"
                :page-size="pageSize"
                :pager-count="5"
                layout="prev, pager, next"
                :total="vlist.meta && vlist.meta.total"
              >
              </el-pagination>
            </div>
            <div class="video-list-bottom-left align-center" v-else>
              <div class="" style="color: #93999f">暂无相关数据</div>
            </div>
            <div class="video-list-bottom-right">
              <el-form label-position="right" label-width="110px" class="addForm" :model="form" :rules="rules" ref="form">
                <!--            <el-form-item label="上传视频">-->

                <!--            </el-form-item>-->
                <el-form-item label="封面图" prop="cover_picture">
                  <upload-file @successUrl="imgUrl" :limit="1" :folder="'discover'" :url="url" :default_value="new Array(form.cover_picture ? form.cover_picture : '')"></upload-file>
                </el-form-item>
                <el-form-item label="标题" prop="title">
                  <el-input class="register-input" v-model="form.title" type="textarea" maxlength="50" show-word-limit :rows="3"></el-input>
                </el-form-item>
                <!--          <el-form-item label="简介">-->
                <!--            <el-input class="register-input"   type="textarea" :rows="3" ></el-input>-->
                <!--          </el-form-item>-->
                <el-form-item label="简介" prop="summary">
                  <el-input class="register-input" v-model="form.summary" type="textarea" :rows="3" maxlength="200" show-word-limit></el-input>
                </el-form-item>
                <el-form-item label="转化视频名称">
                  <el-input class="width-input" v-model="form.conversion_name" @input="$forceUpdate()"></el-input>
                </el-form-item>
                <el-form-item label="转化视频地址">
                  <el-input class="width-input" v-model="form.conversion_url" @input="$forceUpdate()"></el-input>
                </el-form-item>

                <el-form-item label="创作者" prop="author_user_id" v-if="is_admin_middleground === 'true'">
                  <el-select class="authorselect" v-model="form.author_user_id" clearable filterable placeholder="请搜索或选择创作者">
                    <el-option v-for="item in authorsList" :key="item.id" :label="`${item.nickname}-${item.mobile}`" :value="item.id">
                      <div @click="onAuthorOption(item)">{{ item.nickname }}-{{ item.mobile }}</div>
                    </el-option>
                  </el-select>
                </el-form-item>
                <el-form-item label="原创声明" prop="create_type">
                  <el-radio v-model="form.create_type" :label="1">原创</el-radio>
                  <el-radio v-model="form.create_type" :label="2">转载</el-radio>
                </el-form-item>
                <el-form-item label="来源" prop="source" v-if="form.create_type == 2">
                  <el-input class="width-input" v-model="form.source" maxlength="50" show-word-limit></el-input>
                </el-form-item>

                <el-form-item label="金币规则">
                  <el-select v-model="form.rule_item_id" placeholder="请选择" clearable style="width: 210px">
                    <el-option v-for="item in ruleoptions" :key="item.id" :label="item.title + '(' + item.details.val + '金币' + ')'" :value="item.id"> </el-option>
                  </el-select>
                </el-form-item>
                <el-form-item label="标签">
                  <el-tag v-for="tag in form.tags" :key="tag.name" closable :disable-transitions="false" @close="handleClose(tag)">
                    {{ tag.name }}
                  </el-tag>
                  <el-select class="input-new-tag" v-if="inputVisible" v-model="inputValue" ref="saveTagInput" size="small" @change="handleInputConfirm">
                    <el-option v-for="item in options" :key="item.id" :label="item.name" :value="item.id" :disabled="item.disabled"> </el-option>
                  </el-select>
                  <el-button v-else class="button-new-tag" size="small" @click="showInput">+ 新增标签</el-button>
                </el-form-item>
                <el-form-item label="同步到平台">
                  <el-radio v-model="form.is_square" :label="1">是</el-radio>
                  <el-radio v-model="form.is_square" :label="0">否</el-radio>
                </el-form-item>
              </el-form>
              <div class="form-operation">
                <el-button class="form-operation-item" round @click="$router.go(-1)">取消</el-button>
                <el-button class="form-operation-item" type="primary" round @click="sub(true)" v-if="isAdd || this.form.publish_status === 0">发布</el-button>
                <el-button class="form-operation-item draft" round v-if="isAdd" @click="sub()">存草稿</el-button>
                <el-button class="form-operation-item" type="primary" round @click="sub()" v-else v-text="this.form.publish_status === 0 ? '保存' : '更新'"></el-button>
              </div>
            </div>
          </div>
        </div>
      </el-tab-pane>
    </el-tabs>
    <div class="preview">
      <div class="eye vertical-center" @click="dialogVisible = true">
        <img src="~assets/images/data/eye.png" alt="" />
        &nbsp&nbsp预览
      </div>
      <div class="hover vertical-center" @click="dialogVisible = true">
        <img src="~assets/images/data/eye-hover.png" alt="" />
        &nbsp&nbsp预览
      </div>
    </div>
    <el-dialog :title="form.title" :visible.sync="dialogVisible" v-if="dialogVisible" width="658px">
      <play-video :file_id="form.m3u8" class="play-video"></play-video>
      <div class="" v-html="form.summary" style="font-size: 12px; font-weight: 400; color: #71777d; margin-top: 30px; max-height: 200px; overflow-y: auto"></div>
      <!--      <span slot="footer" class="dialog-footer">-->
      <!--    <el-button type="primary" @click="dialogVisible = false" style="border-radius: 21px;width: 140px;height: 42px;">确 定</el-button>-->
      <!--  </span>-->
    </el-dialog>
    <!--引导认证-->
    <SpringFrame
      :authentication="authentication"
      :bankCark="bankCark"
      @handleCloseAuthenticationBank="handleCloseAuthenticationBank"
      @handleCloseAuthentication="handleCloseAuthentication"
      @goBankPage="goBankPage"
    ></SpringFrame>
  </div>
</template>

<script>
import uploadFile from 'components/uploadFile';
import SpringFrame from 'components/SpringFrame';
import uploadVideo from 'components/uploadVideo';
import PlayVideo from 'components/playVideo';
import _ from 'lodash';
export default {
  name: 'Create',
  components: { uploadFile, uploadVideo, PlayVideo, SpringFrame },
  data() {
    return {
      authentication: false,
      bankCark: false,
      activeName: 'create',
      dialogVisible: false,
      url: 'image/browse',
      authorsList: [], // 作者列表
      form: {
        tags: [],
        title: '',
        create_type: 1,
        cover_picture: '',
        rule_item_id: '',
        conversion_name: '',
        conversion_url: '',
        author_user_id: '',
        is_square: 0,
      },
      rules: {
        cover_picture: [{ required: true, message: '请上传封面图', trigger: 'blur' }],
        title: [{ required: true, message: '请填写标题', trigger: 'blur' }],
        content: [{ required: true, message: '请填写正文', trigger: 'blur' }],
        create_type: [{ required: true, message: '请选择', trigger: 'blur' }],
        source: [{ required: true, message: '请填写来源', trigger: 'blur' }],
        author_user_id: [{ required: true, message: '请选择作者', trigger: 'change' }],
      },
      inputVisible: false,
      inputValue: '',
      isAdd: true,
      options: [],
      value: '',
      videoConfig: {
        type: 'discover',
      },
      ruleoptions: [],
      vlist: [],
      delId: '',
      page: 1,
      pageSize: 7,
      approval: 2, // 职业认证
      bind_bank: 'false', // 绑定银行卡
      // is_circle_author: 'false', // 创作者
      is_admin_middleground: 'false', // 管理员
      is_has_benefit_circle: 'false', // 是否参与收益频道
    };
  },
  created() {
    this.getTags();
    this.getVlist();
    this.getRule();
    this.getAuthorsList();
    if (this.$route.query.id !== undefined) {
      this.getInfo();
    }
    this.approval = Number(localStorage.getItem('is_approval'));
    console.log(this.approval, '=========');
    this.bind_bank = localStorage.getItem('is_bind_bank');
    this.is_circle_author = localStorage.getItem('is_circle_author');
    this.is_admin_middleground = localStorage.getItem('is_admin_middleground');
    this.is_has_benefit_circle = localStorage.getItem('is_has_benefit_circle');
    console.log(this.bind_bank, 'oooo');
    if (this.approval !== 1 && this.is_admin_middleground === 'false') {
      this.authentication = true;
    } else if (this.approval === 1 && this.bind_bank === 'false' && this.is_admin_middleground === 'false' && this.is_has_benefit_circle === 'true') {
      this.bankCark = true;
    }
  },
  methods: {
    // 选择创作者
    onAuthorOption(item) {
      this.form.author = item.nickname;
    },
    // 取消弹框
    handleCloseAuthentication() {
      this.$router.go(-1);
    },
    // 取消弹框
    handleCloseAuthenticationBank() {
      this.bankCark = false;
    },
    // 绑定银行卡
    goBankPage() {
      this.$router.push('/profile?activeName=third');
    },
    getAuthorsList() {
      let url = this.$api.authors;
      this.$http.get(url, true).then((res) => {
        if (res.data.success) {
          this.authorsList = res.data.data;
        }
      });
    },
    //获取规则
    getRule() {
      let url = this.$api.ruleItems + `?code=video`;
      this.$http.get(url, true).then((res) => {
        if (res.data.success) {
          this.ruleoptions = res.data.data;
        }
      });
    },
    //删除标签
    handleClose(tag) {
      this.form.tags.splice(this.form.tags.indexOf(tag), 1);
      this.options.forEach(function (item, index) {
        if (item.id === tag.id) {
          item.disabled = false;
        }
      });
    },
    //新增标签
    showInput() {
      this.inputVisible = true;
    },
    // 分页数据
    handleCurrentChange(val) {
      this.page = val;
      this.getVlist();
    },
    handleInputConfirm() {
      let inputValue = this.inputValue;
      let as = '';
      if (inputValue) {
        this.options.forEach(function (item, index) {
          if (item.id === inputValue) {
            as = index;
          }
        });
        this.form.tags.push(this.options[as]);
        this.options[as].disabled = true;
        this.form.tags = this.form.tags.reduce((cur, next) => {
          this.form.tags[next.id] ? '' : (this.form.tags[next.id] = true && cur.push(next));
          return cur;
        }, []);
        // this.options.splice(as,1)
      }
      this.inputVisible = false;
      this.inputValue = '';
    },
    //获取标签
    getTags() {
      let url = this.$api.tagsCircle;
      this.$http.get(url, true).then((res) => {
        if (res.data.success) {
          this.options = res.data.data;
        }
      });
    },
    //获取信息
    getInfo() {
      this.isAdd = false;
      let url = this.$api.DiscoverContents + `/` + this.$route.query.id;
      this.$http.get(url, true).then((res) => {
        if (res.data.success) {
          this.form = res.data.data;
          this.form.media_id = res.data.data.media.id;
          if (res.data.data.conversion) {
            this.form.conversion_name = res.data.data.conversion.name;
            this.form.conversion_url = res.data.data.conversion.url;
          } else {
            this.form.conversion_name = '';
            this.form.conversion_url = '';
          }

          this.form.m3u8 = res.data.data.media.content.m3u8.high;
          this.delId = res.data.data.media.id;
          let that = this;
          this.form.tags.forEach(function (item, index) {
            that.options.forEach(function (tag, num) {
              if (tag.id === item.id) {
                tag.disabled = true;
              }
            });
          });
        }
      });
    },
    getVlist() {
      if (this.$route.query.id === undefined) {
        let urls = this.$api.medias + `?is_paginate=1&type=discover&page=${this.page}&pageSize=${this.pageSize}`;
        this.$http.get(urls, true).then((res) => {
          if (res.data.success) {
            this.vlist = res.data.data;
            console.log(this.vlist.data[0], '================');
            // if (this.vlist.data[0].content.m3u8) {
            // } else {
            //   setTimeout(() => {
            //     this.getVlist();
            //   }, 5000);
            // }
          }
        });
      } else {
        let urls = this.$api.medias + `?is_paginate=1&type=discover&model_uuid=${this.$route.query.id}&page=${this.page}&pageSize=${this.pageSize}`;
        this.$http.get(urls, true).then((res) => {
          if (res.data.success) {
            this.vlist = res.data.data;
            console.log(this.vlist.data[0], '++++++++++++');
            if (this.vlist.data[0].content.m3u8) {
            } else {
              setTimeout(() => {
                this.getVlist();
              }, 5000);
            }
          }
        });
      }
    },
    //删除
    deletevideo: _.debounce(function (id) {
      let urls = this.$api.medias + `/${id}`;
      this.$http
        .del(urls, true)
        .then((res) => {
          if (res.data.success) {
            this.$message.success('删除成功');
            this.getVlist();
          } else {
            this.$message.error(res.data.message);
          }
        })
        .catch((error) => {
          this.$message.error(error.response.data.message);
        });
    }, 500),
    //选择视频
    select(name, id, val, cover_url) {
      if (val.m3u8) {
        this.form.m3u8 = val.m3u8.high;
        this.form.title = name;
        this.form.media_id = id;
        if (cover_url) {
          this.form.cover_picture = cover_url;
        } else {
          this.form.cover_picture = '';
        }
      } else {
        this.$message.info('视频转码中');
      }

      this.$forceUpdate();
    },
    //新增修改草稿
    sub: _.debounce(function (val) {
      if (this.form.tags.length > 8) {
        this.$message.error('最多选择8个标签');
        return;
      }
      if ((this.form.conversion_name != '' && this.form.conversion_url == '') || (this.form.conversion_name == '' && this.form.conversion_url != '')) {
        this.$message.info('转化视频名称与地址需同时存在');
        return;
      }
      this.$refs.form.validate((valid) => {
        if (valid) {
          if (this.isAdd) {
            //新增
            if (val) {
              let url = this.$api.DiscoverContents;
              let data = JSON.parse(JSON.stringify(this.form));
              data.is_draft = 0;
              data.type = 'video';
              data.tags = data.tags.map(function (item) {
                return item['id'];
              });
              this.$http
                .post(url, data, true)
                .then((res) => {
                  if (res.data.success) {
                    this.$message.success('新增成功');
                    // this.form = res.data.data
                    this.$router.push({
                      path: '/creation',
                      query: {
                        artivleType: 'three',
                      },
                    });
                  } else {
                    this.$message.error(res.data.message);
                  }
                })
                .catch((error) => {
                  this.$message.error(error.response.data.message);
                });
            }
            //草稿
            else {
              let url = this.$api.DiscoverContents;
              let data = JSON.parse(JSON.stringify(this.form));
              data.is_draft = 1;
              data.type = 'video';
              data.tags = data.tags.map(function (item) {
                return item['id'];
              });
              this.$http
                .post(url, data, true)
                .then((res) => {
                  if (res.data.success) {
                    this.$message.success('存入草稿');
                    // this.form =res.data.data
                    // this.$router.push('/article/article')
                    this.$router.push({
                      path: '/creation',
                      query: {
                        artivleType: 'four',
                      },
                    });
                  } else {
                    this.$message.error(res.data.message);
                  }
                })
                .catch((error) => {
                  this.$message.error(error.response.data.message);
                });
            }
          }
          //修改
          else {
            if (val) {
              let url = this.$api.DiscoverContents + `/` + this.form.uuid;
              let data = JSON.parse(JSON.stringify(this.form));
              data.is_draft = 0;

              data.type = 'video';
              data.tags = data.tags.map(function (item) {
                return item['id'];
              });
              this.$http
                .put(url, data, true)
                .then((res) => {
                  if (res.data.success) {
                    this.$message.success('修改成功');
                    // this.form =res.data.data
                    this.$router.push({
                      path: '/creation',
                      query: {
                        artivleType: 'three',
                      },
                    });
                  } else {
                    this.$message.error(res.data.message);
                  }
                })
                .catch((error) => {
                  this.$message.error(error.response.data.message);
                });
            } else {
              let url = this.$api.DiscoverContents + `/` + this.form.uuid;
              let data = JSON.parse(JSON.stringify(this.form));
              if (data.publish_status === 1) {
                data.is_draft = 0;
              } else {
                data.is_draft = 1;
              }
              data.type = 'video';
              data.tags = data.tags.map(function (item) {
                return item['id'];
              });
              this.$http
                .put(url, data, true)
                .then((res) => {
                  if (res.data.success) {
                    this.$message.success('修改成功');
                    // this.form =res.data.data
                    // this.$router.push('/article/article')
                    if (this.form.publish_status === 0) {
                      this.$router.push({
                        path: '/creation',
                        query: {
                          artivleType: 'four',
                        },
                      });
                    } else {
                      this.$router.push({
                        path: '/creation',
                        query: {
                          artivleType: 'three',
                        },
                      });
                    }
                  } else {
                    this.$message.error(res.data.message);
                  }
                })
                .catch((error) => {
                  this.$message.error(error.response.data.message);
                });
            }
          }
        } else {
          return false;
        }
      });
    }, 500),
    // 视频上传状态
    videoStatus(data) {
      if (data) {
        this.getVlist();
      }
    },
    //上传图片
    imgUrl(data) {
      if (!data[data.length - 1]) {
        data[data.length - 1] = '';
      }
      this.form.cover_picture = data[data.length - 1];
      this.$refs.form.clearValidate('cover_picture');
    },
    // tab切换
    handleClick(tab, event) {
      if (tab.name === 'courses') {
        this.$router.go(-1);
      }
    },
  },
};
</script>

<style lang="less" scoped>
@import '~assets/less/article/text.less';
.total-left {
  padding: 10px 0 10px 12px;
  font-size: 14px;
  font-weight: 400;
  color: #93999f;
  border-bottom: 1px solid #ebeff2;
  .total-left-text {
    color: @theme;
  }
}
</style>
